import { JSORMBase, Model } from 'jsorm'
import { kQMSAuthToken } from 'services/auth'

declare const __FURTHER_INSIGHTS_API_URL__: string

@Model()
class ApplicationRecord extends JSORMBase {
  static baseUrl = __FURTHER_INSIGHTS_API_URL__
  static apiNamespace = '/api/v1'
  static jwtStorage = kQMSAuthToken
  static sync = true
  static generateAuthHeader = token => `Bearer ${token}`

  static load(id: string): JSORMBase {
    const model = new this(this.store.data[`${this.jsonapiType}-${id}`])

    if (model && model.id) {
      model.isPersisted = true
    }
    return model
  }

  fullyLoaded: boolean = false

  delete = (): Promise<any> => (this.isPersisted ? this.destroy() : Promise.resolve())
}

export default ApplicationRecord
