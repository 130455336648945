import { injectReducer } from 'store/reducers'

export default (store) => ({
  path: 'quizzes',
  /*  Async getComponent is only invoked when route matches   */
  getComponent (nextState, cb) {
    /*  Webpack - use 'require.ensure' to create a split point
        and embed an async module loader (jsonp) when bundling   */
    require.ensure([], (require) => {
      /*  Webpack - use require callback to define
          dependencies for bundling   */
      const QuizIndex = require('./containers/QuizIndex').default

      const indexReducer = require('./reducers').default

      /*  Add the reducer to the store on key 'counter'  */
      // injectReducer(store, { key: 'quizzes', reducer: quizzesReducer })
      // injectReducer(store, { key: 'users', reducer: usersReducer })
      // injectReducer(store, { key: 'offers', reducer: offersReducer })

      injectReducer(store, { key: 'quizzesIndex', reducer: indexReducer })

      /*  Return getComponent   */
      cb(null, QuizIndex)

    /* Webpack named bundle   */
    }, 'quizzes')
  }
})
