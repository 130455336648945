import QuizEditHeader from './containers/QuizEditHeader'
import StartPageEdit from '../StartPageEdit'
import QuestionEdit from '../QuestionEdit'
import Offers from '../Offers'

export const createRoutes = (store) => ({
  path: '/quizzes/:id',
  component: QuizEditHeader,
  childRoutes: [
    StartPageEdit(store),
    QuestionEdit(store),
    Offers(store)
  ]
})

export default createRoutes
