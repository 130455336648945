exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".CoreLayout__mainContainer___3li1d {\n}\n\nbody {\n  /* background: var(--page-background); */\n  /* color: var(--text-color-100); */\n}\n", ""]);

// exports
exports.locals = {
	"mainContainer": "CoreLayout__mainContainer___3li1d"
};