import * as React from 'react'
import { withRouter } from 'react-router';

class Dashboard extends React.Component {
  render() {
    return <div>hello</div>
  }
}


export default withRouter(Dashboard)
