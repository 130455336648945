import { Attr, BelongsTo, HasMany, Model } from 'jsorm'
import ApplicationRecord from './ApplicationRecord'

import { Answer, Quiz } from 'models'

@Model()
export class Offer extends ApplicationRecord {
  static jsonapiType = 'offers'

  @BelongsTo() quiz: Quiz
  @HasMany() answers: Answer[]

  @Attr() id: string
  @Attr() format: string
  @Attr()
  range: {
    min: number
    max: number
  }
  @Attr()
  outcome: {
    text: string
    description: string
  }
  @Attr()
  links: [
    {
      src: string
      text: string
      new_tab?: boolean
    }
  ]
}
