// import { injectReducer } from 'store/reducers'

import OfferEdit from './routes/OfferEdit'

export default (store) => ({
  path: '/quizzes/:id/offers',
  childRoutes: [
    OfferEdit(store)
  ],
  /*  Async getComponent is only invoked when route matches   */
  getComponent (nextState, cb) {
    /*  Webpack - use 'require.ensure' to create a split point
        and embed an async module loader (jsonp) when bundling   */
    require.ensure([], (require) => {
      /*  Webpack - use require callback to define
          dependencies for bundling   */
      const Offers = require('./containers/OffersContainer').default
      // const offersReducer = require('reducers/offers').default
      // const quizzesReducer = require('reducers/quizzes').default

      /*  Add the reducer to the store on key 'counter'  */
      // injectReducer(store, { key: 'offers', reducer: offersReducer })
      // injectReducer(store, { key: 'quizzes', reducer: quizzesReducer })

      /*  Return getComponent   */
      cb(null, Offers)

    /* Webpack named bundle   */
    }, 'offers')
  }
})
