import { Attr, HasMany, Model } from 'jsorm'
import ApplicationRecord from './ApplicationRecord'

import { Quiz } from 'models'

@Model()
export class User extends ApplicationRecord {
  static jsonapiType = 'users'

  @HasMany() quizzes: Quiz[]

  @Attr() id: string
  @Attr({ persist: false })
  email: string
}
