// import { injectReducer } from 'store/reducers';

export default (store) => ({
  path: '/quizzes/:id/ga',
  /*  Async getComponent is only invoked when route matches   */
  getComponent (nextState, cb) {
    /*  Webpack - use 'require.ensure' to create a split point
        and embed an async module loader (jsonp) when bundling   */
    require.ensure([], (require) => {
      /*  Webpack - use require callback to define
          dependencies for bundling   */
      const GoogleAnalytics = require('./containers/GoogleAnalyticsContainer').default
      // const quizzesReducer = require('reducers/quizzes').default
      // const offersReducer = require('reducers/offers').default

      /*  Add the reducer to the store on key 'counter'  */
      // injectReducer(store, { key: 'offers', reducer: offersReducer })
      // injectReducer(store, { key: 'quizzes', reducer: quizzesReducer })

      /*  Return getComponent   */
      cb(null, GoogleAnalytics)

    /* Webpack named bundle   */
    }, 'googleAnalytics')
  }
})
