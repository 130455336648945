import * as auth from 'services/auth'
import Auth0Lock from 'auth0-lock'
import { push } from 'react-router-redux'

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'

const loginSuccess = (profile) => ({ type: LOGIN_SUCCESS, profile })
const loginError = (err) => ({ type: LOGIN_ERROR, err })
const logoutSuccess = () => ({ type: LOGOUT_SUCCESS })

export const login = () => (dispatch, getState) => {
  const lock = new Auth0Lock(__AUTH0_CLIENT_ID__, __AUTH0_DOMAIN__, {
    popup: true,
    auth: {
      redirect: false,
      responseType: 'token id_token',
      audience: `https://${__AUTH0_DOMAIN__}/userinfo`,
      params: {
        scope: 'openid email profile'
      }
    },
    autoclose: true,
    theme: { logo: '/img/further-insights-h-black.png' }
  })

  lock.on('authenticated', (authResult) => {
    lock.getUserInfo(authResult.accessToken, (error, profile) => {
      if (error) { dispatch(loginError(error)); return }
      auth.setProfile(profile)
      auth.setToken(authResult.idToken)
      dispatch(push('/quizzes'))
      dispatch(loginSuccess(profile))
    })
  }).show()
}

export const logout = () => (dispatch, getState) => {
  auth.logout()
  dispatch(logoutSuccess())
  dispatch(push('/'))
}

const ADMIN_ROLE = 'admin'
const isAdmin = () => {
  const token = auth.getDecodedToken()
  if (token && token['http://furtherinsights.com/roles']) {
    return (token['http://furtherinsights.com/roles']).includes(ADMIN_ROLE)
  }
}

export const reducer = (state = {
  isAuthenticated: auth.isLoggedIn(),
  profile: {
    ...auth.getProfile(),
    isAdmin: isAdmin()
  },
  error: ''
}, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        profile: {
          ...action.profile,
          isAdmin: isAdmin()
        },
        error: ''
      }
    case LOGIN_ERROR:
      return {
        ...state,
        isAuthenticated: false,
        profile: {},
        error: action.error
      }
    case LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        profile: {},
        error: ''
      }
    default:
      return state
  }
}

export const getAuth = (state) => state.auth

export default reducer
