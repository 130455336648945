import bugsnag from 'bugsnag-js'
import createPlugin from 'bugsnag-react'
import createBrowserHistory from 'history/lib/createBrowserHistory'
import React from 'react'
import ReactDOM from 'react-dom'
import { useRouterHistory } from 'react-router'
import { syncHistoryWithStore } from 'react-router-redux'

import AppContainer from './containers/AppContainer'
import createStore from './store/createStore'


export const bugsnagClient = bugsnag({ apiKey: __BUGSNAG_API_KEY__ })
// ========================================================
// Browser History Setup
// ========================================================
const browserHistory = useRouterHistory(createBrowserHistory)({
  basename: __BASENAME__
}) as any

// ========================================================
// Store and History Instantiation
// ========================================================
// Create redux store and sync with react-router-redux. We have installed the
// react-router-redux reducer under the routerKey "router" in src/routes/index.js,
// so we need to provide a custom `selectLocationState` to inform
// react-router-redux of its location.
const initialState = {}
export const store = createStore(initialState, browserHistory)
const history = syncHistoryWithStore(browserHistory, store, {
  selectLocationState: state => state.router
})

// ========================================================
// Developer Tools Setup
// ========================================================
// if (__DEBUG__) {
// if (window.devToolsExtension) {
// window.devToolsExtension.open()
// }
// }

// ========================================================
// Render Setup
// ========================================================
const MOUNT_NODE = document.getElementById('root')

let render = (routerKey = null) => {
  const routes = require('./routes/index').default(store)
  const ErrorBoundary = bugsnagClient.use(createPlugin(React))
  ReactDOM.render(
    <ErrorBoundary>
      <AppContainer store={store} history={history} routes={routes} routerKey={routerKey} />
    </ErrorBoundary>,
    MOUNT_NODE
  )
}

// Enable HMR and catch runtime errors in RedBox
// This code is excluded from production bundle
if (__DEV__ && module.hot) {
  // const {whyDidYouUpdate} = require('why-did-you-update')
  // whyDidYouUpdate(React)
  const renderApp = render
  const renderError = error => {
    const RedBox = require('redbox-react').default

    ReactDOM.render(<RedBox error={error} />, MOUNT_NODE)
  }
  render = () => {
    try {
      renderApp(Math.random())
    } catch (error) {
      renderError(error)
    }
  }
  module.hot.accept(['./routes/index'], () => render())
}

// ========================================================
// Go!
// ========================================================
render()
