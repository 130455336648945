import { injectReducer } from 'store/reducers'

export default (store) => ({
  path: '/quizzes/:id/preview',
  /*  Async getComponent is only invoked when route matches   */
  getComponent (nextState, cb) {
    /*  Webpack - use 'require.ensure' to create a split point
        and embed an async module loader (jsonp) when bundling   */
    require.ensure([], (require) => {
      /*  Webpack - use require callback to define
          dependencies for bundling   */
      const QuizPreview = require('./containers/QuizPreviewContainer').default

      //const reducer = require('./reducers').default
      // const quizzesReducer = require('reducers/quizzes').default

      /*  Add the reducer to the store on key 'counter'  */
      // injectReducer(store, { key: 'quizzes', reducer: quizzesReducer })


      /*  Return getComponent   */
      cb(null, QuizPreview)

    /* Webpack named bundle   */
    }, 'quizPreview')
  }
})
