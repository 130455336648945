import { Attr, BelongsTo, HasMany, Model } from 'jsorm'
import { Answer, LeadGeneration, Quiz } from 'models'

import ApplicationRecord from './ApplicationRecord'

export enum EQuestionType {
  LeadGeneration = 'LeadGeneration',
  MultipleChoice = 'MultipleChoice'
}

@Model()
export class Question extends ApplicationRecord {
  static jsonapiType = 'questions'

  @BelongsTo() quiz: Quiz
  @HasMany() answers: Answer[]
  @HasMany() leadGenerations: LeadGeneration[]

  @Attr() id: string
  @Attr() prompt: string
  @Attr() position: number
  @Attr()
  image: {
    original: string | null
  }
  @Attr() type: EQuestionType
}

export async function updateQuestion(question: Question, values) {
  question.assignAttributes(values)
  if (question.isDirty()) {
    question.save()
  }
}

export async function addAnswer(question: Question) {
  question.answers = [...question.answers, new Answer()].map((answer, idx) => {
    answer.position = idx + 1
    return answer
  })
  return question.save()
}
