import { Attr, BelongsTo, HasMany, Model } from 'jsorm';
import { DuplicateQuiz, Offer, Question, Result, User } from 'models';

import { Answer } from './Answer';
import ApplicationRecord from './ApplicationRecord';

export enum EQuizFormat {
  PersonalityQuiz = 'PersonalityQuiz',
  GradedQuiz = 'GradedQuiz',
  OceanQuiz = 'OceanQuiz'
}

@Model()
export class Quiz extends ApplicationRecord {
  static jsonapiType = 'quizzes'

  @HasMany() questions: Question[]
  @HasMany() offers: Offer[]
  @HasMany() results: Result[]
  @BelongsTo() user: User

  @Attr() answerButtonColor: string
  @Attr() answerFontColor: string
  @Attr() answerFontFamily: string
  @Attr() answersHolderColor: string
  @Attr() blockAds: boolean
  @Attr() buttonFontColor: string
  @Attr() buttonFontFamily: string
  @Attr({ persist: false })
  createdAt: string
  @Attr() customAd: string
  @Attr() description: string
  @Attr() format: EQuizFormat
  @Attr() hideLogo: boolean
  @Attr() id: string
  @Attr()
  image: {
    original: string | null
  }
  @Attr() imageBlurColor: string
  @Attr() leadGenerationAllowSkip: boolean
  @Attr() leadGenerationSkipText: string
  @Attr() leadGenerationButtonText: string
  @Attr() leadGenerationPromptText: string
  @Attr() leadGenerationConsentText: string
  @Attr() leadGenerationFields: ILeadGenerationField[]
  @Attr() notes: string
  @Attr() paragraphFontColor: string
  @Attr() paragraphFontFamily: string
  @Attr() progressFontColor: string
  @Attr() progressFontFamily: string
  @Attr() published: boolean
  @Attr() requireLeadGenerationForResults: boolean
  @Attr() titleFontColor: string
  @Attr() titleFontFamily: string
  @Attr() title: string
  @Attr({ persist: false })
  updatedAt: string
}

export enum ELeadGenerationTypes {
  checkbox = 'Checkbox',
  dropdown = 'Dropdown',
  text = 'Text'
}

interface ILeadGenerationField {
  title: string
  type: ELeadGenerationTypes
  placeholder: string
  options?: string[]
}

export async function fetchQuiz(id: string): Promise<Quiz> {
  if (!id) {
    return null
  }

  const answerScope = Answer.order({ position: 'asc' })
  const questionScope = Question.order({ position: 'asc' })

  const { data: quiz } = await Quiz.includes(['offers', { questions: { answers: 'offer' } }])
    .merge({ questions: questionScope, answers: answerScope })
    .find(id)
  return quiz
}

export async function fetchQuizWithResults(id: string): Promise<Quiz> {
  if (!id) {
    return null
  }

  const answerScope = Answer.order({ position: 'asc' })
  const questionScope = Question.order({ position: 'asc' })

  const { data: quiz } = await Quiz.includes(['offers', { questions: { answers: 'offer' }, results: 'answers' }])
    .merge({ questions: questionScope, answers: answerScope })
    .find(id)
  return quiz
}

export async function fetchQuizzes() {
  const { data: quizzes } = await Quiz.select({
    quizzes: ['title', 'updated_at', 'notes', 'id', 'user'],
    user: ['email']
  })
    .includes('user')
    .order({ updated_at: 'desc' })
    .all()
  return quizzes
}

export async function createQuiz(format: EQuizFormat): Promise<Quiz> {
  const newQuiz = new Quiz({ format })
  await newQuiz.save()
  return newQuiz
}

export async function duplicateQuiz(quiz: Quiz) {
  const duplicate = new DuplicateQuiz({ id: quiz.id })
  return duplicate.save()
}

export async function deleteQuiz(quiz: Quiz) {
  return quiz.destroy()
}

export async function addQuestion(quiz: Quiz) {
  const question = new Question({ position: quiz.questions.length + 1 })
  quiz.questions.push(question)
  return quiz.save({ with: 'questions' }).then(success => ({ quiz, question }))
}

export async function addOffer(quiz: Quiz, format) {
  const offer = new Offer({
    outcome: { text: '', description: '', image: { original: '' } },
    format,
    range: {
      min: 0,
      max: 100
    }
  })
  quiz.offers.push(offer)
  return quiz.save({ with: 'offers' }).then(success => ({ quiz, offer }))
}

export async function updateQuizQuestionsOrder(quiz: Quiz, questions: Question[]) {
  quiz.questions = questions
  await quiz.save({ with: 'questions' })
  return quiz
}
