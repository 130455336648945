import * as _colors from 'material-ui/styles/colors';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import * as _spacing from 'material-ui/styles/spacing';
import * as _colorManipulator from 'material-ui/utils/colorManipulator';
import * as React from 'react';
import { Provider, ProviderProps } from 'react-redux';
import { Router } from 'react-router';

// setup custom Material UI theme
const _spacing2 = _interopRequireDefault(_spacing)

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj } }

const furtherInsightsPurple = '#6140FF'
const furtherInsightsYellow = '#FFCE52'
const furtherInsightsPink = '#CE6DE7'
const newsUpBlue = '#6FC6EF'

const furtherInsightsTheme = {
  spacing: _spacing2.default,
  fontFamily: 'Roboto, sans-serif',
  palette: {
    primary1Color: furtherInsightsPurple, // _colors.cyan500,
    primary2Color: newsUpBlue, // _colors.cyan700,
    primary3Color: _colors.grey400,
    accent1Color: furtherInsightsYellow, // _colors.pinkA200,
    accent2Color: newsUpBlue, // _colors.grey100,
    accent3Color: _colors.grey500,
    textColor: _colors.darkBlack,
    secondaryTextColor: _colorManipulator.fade(_colors.darkBlack, 0.54),
    alternateTextColor: _colors.white,
    canvasColor: _colors.white,
    borderColor: _colors.grey300,
    disabledColor: _colorManipulator.fade(_colors.darkBlack, 0.3),
    pickerHeaderColor: _colors.cyan500,
    clockCircleColor: _colorManipulator.fade(_colors.darkBlack, 0.07),
    shadowColor: _colors.fullBlack
  }
}

interface IProps extends ProviderProps {
  history: any
  routes: ReactRouter.RouteConfig
  routerKey: number
}

class AppContainer extends React.Component<IProps> {
  render () {
    const { history, routes, routerKey, store } = this.props

    return (
      <MuiThemeProvider muiTheme={getMuiTheme(furtherInsightsTheme)}>
        <Provider store={store}>
          <Router history={history} children={routes} key={routerKey} />
        </Provider>
      </MuiThemeProvider>
    )
  }
}

export default AppContainer
