import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import Auth from '../Auth'
import { Toolbar, ToolbarGroup, ToolbarTitle } from 'material-ui/Toolbar'

/* ---------- constants */

const kQuizzesLogoStyle = {
  'maxWidth': '100%',
  'maxHeight': '100%',
  'paddingTop': '4px',
  'paddingBottom': '4px',
  'height': '85%',
  'objectFit': 'contain'
}

/* ---------- classes */

export class Header extends Component {
  handleClick = () => {
    this.props.router.push('/')
  }

  render() {
    const { onClickTOSCheck } = this.props
    return (
      <Toolbar>
        <ToolbarGroup>
          <img src="/img/further-insights.png"
            style={kQuizzesLogoStyle}
            alt="Further Insights"
            onClick={this.handleClick} />
        </ToolbarGroup>
        <Auth onClickTOSCheck={onClickTOSCheck} />
      </Toolbar>
    )
  }
}

Header = withRouter(Header)

Header.propTypes = {
  onClickTOSCheck: PropTypes.func.isRequired
}

export default Header
