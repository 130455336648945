import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import * as React from 'react';

import Header from '../../components/Header';

import styles from './CoreLayout.css'
/* ---------- constants */

const kToSAcceptedLocalStorageName = 'FI_QMS_TOS_ACCEPTED_VERSION_1_0_0'

// some value other than 0
const kToSAcceptedConfirmationValue = 5150

// toggle this to activate / deactivate the ToS acceptance check UX flow
const kToSCheckIsActive = false

/* ---------- classes */

/* this store facilitates saving ToS acceptance state */
const TermsOfServiceStateStore = (() => {
  const onToSCheckStartCallbacks = []

  // this is intended to be replaced with another component's logic (e.g., continued login flow)
  let onDialogCloseCallback = (accepted) => {
    /*if (accepted) {
      console.log("TermsOfServiceStateStore: ToS was accepted!")
    } else {
      console.log("TermsOfServiceStateStore: ToS was not accepted!")
    }*/
  }

  function addOnToSCheckStartListener(callback) {
    onToSCheckStartCallbacks.push(callback)
  }

  function onToSCheckStart() {
    onToSCheckStartCallbacks.forEach( callback => callback())
  }

  function onToSDialogClose(accepted) {
    onDialogCloseCallback(accepted)
  }

  function setOnDialogCloseCallback(callback) {
    onDialogCloseCallback = callback
  }

  function getToSAccepted() {
    let tosAccepted = false

    if ('undefined' !== typeof (Storage)) {
      if (kToSAcceptedConfirmationValue === parseInt(localStorage.getItem(kToSAcceptedLocalStorageName), 10)) {
        // console.log("ToS has previously been accepted")
        tosAccepted = true
      }
    } else {
      // console.log("no local storage - ToS Acceptance will be shown")
    }

    return tosAccepted
  }

  function setToSAccepted(value) {
    if ('undefined' !== typeof (Storage)) {
      localStorage.setItem(kToSAcceptedLocalStorageName, value)
    } else {
      // console.log("no local storage - ToS Acceptance cannot be remembered")
    }
  }

  return {
    addOnToSCheckStartListener,
    onToSCheckStart,
    onToSDialogClose,
    setOnDialogCloseCallback,
    getToSAccepted,
    setToSAccepted
  }
})()

class TermsOfServiceDialog extends React.Component {
  state = {
    open: false
  };

  componentDidMount() {
    TermsOfServiceStateStore.addOnToSCheckStartListener(() => {
      this.handleOpen()
    })
  }

  handleOpen = () => {
    this.setState({open: true})
  }

  handleAccept = () => {
    TermsOfServiceStateStore.setToSAccepted(kToSAcceptedConfirmationValue)
    TermsOfServiceStateStore.onToSDialogClose(true)
    this.setState({open: false})
  };

  handleCancel = () => {
    TermsOfServiceStateStore.setToSAccepted(false)
    TermsOfServiceStateStore.onToSDialogClose(false)
    this.setState({open: false})
  };

  render() {
    const actions = [
      <FlatButton label="Decline" onClick={this.handleCancel} key={'tos-decline-action'} />,
      <FlatButton label="Accept" primary onClick={this.handleAccept} key={'tos-accept-action'} />
    ]

    // <RaisedButton label="Modal Dialog" onClick={this.handleOpen} />
    return (
      <div>
        <Dialog title="Terms of Service" actions={actions} modal autoScrollBodyContent open={this.state.open} >
          <p />
          <p>Please click below to confirm that you have read and accept the Further Insights Terms and Conditions</p>
          <p><b>Terms of Service ("Terms")</b></p>
          <p>Last updated: March 30, 2017</p>
          <p>Please read these Terms of Service ("Terms", "Terms of Service") carefully before using
          the Further Insights Quiz Management System (https://qms.furtherinsights.com) website and the Further Insights embedded
          quiz system (the "Service") operated by Flying Pig Digital, Inc. ("us", "we", or "our").
          Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms.
          These Terms apply to all visitors, users and others who access or use the Service.
          By accessing or using the Service you agree to be bound by these Terms.
          If you disagree with any part of the terms then you may not access the Service.</p>
          <p><b>Termination</b></p>
          <p>We may terminate or suspend access to our Service immediately, without prior notice or liability,
          for any reason whatsoever, including without limitation if you breach the Terms.
          All provisions of the Terms which by their nature should survive termination shall survive termination,
          including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>
          <p><b>Subscriptions</b></p>
          <p>Some parts of the Service are billed on a subscription basis ("Subscription(s)").
          You will be billed in advance on a recurring basis.</p>
          <p><b>Content</b></p>
          <p>Our Service allows you to post, link, store, share and otherwise make available certain
          information, text, graphics, videos, or other material ("Content").
          Any such material is subject to the copyrights and trademarks of their respective owners.</p>
          <p><b>Links To Other Web Sites</b></p>
          <p>Our Service may contain links to third-party web sites or services that are not owned or controlled
          by Flying Pig Digital. Flying Pig Digital has no control over, and assumes no responsibility for,
          the content, privacy policies, or practices of any third-party web sites or services.
          You further acknowledge and agree that Flying Pig Digital shall not be responsible or liable,
          directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with
          the use of or reliance on any such content, goods or services available on or through any such web sites or services.</p>
          <p><b>Changes</b></p>
          <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time.
          If a revision is material we will try to provide at least 30 days notice
          prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>
          <p><b>Contact Us</b></p>
          <p>If you have any questions about these Terms, please contact us.</p>
        </Dialog>
      </div>
    )
  }
}

/*
this method is the entry point to ToS checks, called by the login button which itself
will pass in a callback function which is called with <true, false> indicating acceptance of the ToS

the callback signature is as follows:
onDialogCloseCallback = function(accepted) {
  if (accepted) {
    console.log("ToS was accepted!")
  } else {
    console.log("ToS was not accepted!")
  }
}
*/
function runToSCheck(onDialogCloseCallback) {
  if (!kToSCheckIsActive) {
    // ToS checks not active, proceed
    onDialogCloseCallback(true)
  } else if (TermsOfServiceStateStore.getToSAccepted()) {
    // user has already accepted the ToS, skip to the end
    onDialogCloseCallback(true)
  } else {
    // setup our ToS acceptance callback
    TermsOfServiceStateStore.setOnDialogCloseCallback(onDialogCloseCallback)
    // spin up the UI
    TermsOfServiceStateStore.onToSCheckStart()
  }
}

interface IQuizLayoutProps {
  children: JSX.Element
}

export const CoreLayout:React.SFC<IQuizLayoutProps> = ({ children }) => (
  <div>
    <Header onClickTOSCheck={runToSCheck} />
    <TermsOfServiceDialog />
    <div className={styles.mainContainer}>
      {children}
    </div>
  </div>
)
