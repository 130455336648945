import { Attr, BelongsTo, Model } from 'jsorm'

import ApplicationRecord from './ApplicationRecord'
import { Question } from './Question'

export enum ELeadgenerationDisplayType {
  TextInput = 'TextInput',
  Checkbox = 'Checkbox',
  Dropdown = 'Dropdown',
  ComboBox = 'ComboBox',
  Radio = 'Radio',
  Emal = 'Email'
}

@Model()
export class LeadGeneration extends ApplicationRecord {
  static jsonapiType = 'lead_generations'

  @BelongsTo() question: Question

  @Attr() id: string
  @Attr() position: number
  @Attr() displayType: ELeadgenerationDisplayType
  @Attr() prompt: string
  @Attr()
  options: {
    text: string
    position: number
  }
}
