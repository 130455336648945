import QuestionEditForm from './routes/QuestionEditForm'

export default (store) => ({
  path: '/quizzes/:id/questions',
  childRoutes: [
    QuestionEditForm(store)
  ],
  /*  Async getComponent is only invoked when route matches   */
  getComponent (nextState, cb) {
    /*  Webpack - use 'require.ensure' to create a split point
        and embed an async module loader (jsonp) when bundling   */
    require.ensure([], (require) => {
      /*  Webpack - use require callback to define
          dependencies for bundling   */
      const QuestionEdit = require('./containers/QuestionEditContainer').default
      // const quizReducer = require('reducers/quizzes').default
      // const questionsReducer = require('reducers/questions').default

      /*  Add the reducer to the store on key 'counter'  */
      // injectReducer(store, { key: 'quizzes', reducer: quizReducer })
      // injectReducer(store, { key: 'questions', reducer: questionsReducer })

      /*  Return getComponent   */
      cb(null, QuestionEdit)

    /* Webpack named bundle   */
    }, 'questionEdit')
  }
})
