import { Attr, BelongsTo, Model } from 'jsorm'
import ApplicationRecord from './ApplicationRecord'

import { Offer, Question } from 'models'

@Model()
export class Answer extends ApplicationRecord {
  static jsonapiType = 'answers'

  @BelongsTo() question: Question
  @BelongsTo() offer: Offer

  @Attr() id: string
  @Attr() position: number
  @Attr() points: number
  @Attr() isCorrect: boolean
  @Attr() text: string
  @Attr() createdAt: string
  @Attr() updatedAt: string
  @Attr() questionId: string
}
