import { reducer as auth } from 'modules/auth'
import { routerReducer as router } from 'react-router-redux'
import { combineReducers } from 'redux'

export const makeRootReducer = asyncReducers => {
  return combineReducers({
    // Add sync reducers here
    router,
    auth,
    ...asyncReducers
  })
}

export const injectReducer = (store, { key, reducer }) => {
  store.asyncReducers[key] = reducer
  store.replaceReducer(makeRootReducer(store.asyncReducers))
}

export default makeRootReducer
