import { decodedToken, isTokenExpired } from './jwtHelper'

const kQMSProfileName = 'FI_QMS_PROFILE'
export const kQMSAuthToken: string = 'FI_AUTH_ID_TOKEN'

export const getProfile = () => {
  // Retrieves the profile data from localStorage
  const profile = localStorage.getItem(kQMSProfileName)
  return profile ? JSON.parse(profile) : {}
}

export const setToken = idToken => {
  localStorage.setItem(kQMSAuthToken, idToken)
}

export const getToken = () => {
  return localStorage.getItem(kQMSAuthToken)
}

export const getDecodedToken = () => {
  const token = getToken()
  if (token) {
    return decodedToken(getToken())
  }
}

export const isLoggedIn = () => {
  const token = getToken()
  return !!token && !isTokenExpired(token)
}

export const setProfile = profile => {
  localStorage.setItem(kQMSProfileName, JSON.stringify(profile))
}

export const logout = () => {
  localStorage.removeItem(kQMSAuthToken)
  localStorage.removeItem(kQMSProfileName)
}
