/*
debugging utilities

usage:
const debug = require('services/debug')
debug.log("hello!")
*/

class debug {
  static log(message) {
    if (__DEBUG__) {
      console.log(message)
    }
  }

  static warn(message) {
    if (__DEBUG__) {
      console.warn(message)
    }
  }

  static error(message) {
    if (__DEBUG__) {
      console.error(message)
    }
  }

  static assert(condition, message) {
    if (__DEBUG__) {
      if (!condition) {
        message = message || 'Assertion failed!'
        if (typeof Error !== 'undefined') {
          throw new Error(message)
        } else {
          throw message
        }
      }
    }
  }
}

export default debug
