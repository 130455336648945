import { Tab, Tabs } from 'material-ui/Tabs';
import { fetchQuiz, Quiz } from 'models/Quiz';
import * as React from 'react';
import { InjectedRouter, withRouter } from 'react-router';

interface IQuizContainerProps extends ReactRouter.RouteComponentProps<{ id: string }, {}> {
  children: JSX.Element
  router: InjectedRouter
}

class QuizEditHeader extends React.Component<IQuizContainerProps,{quiz: Quiz}> {
  constructor(props) {
    super(props)
    this.state = {
      quiz: null
    }
  }

  componentDidMount() {
    fetchQuiz(this.props.params.id)
      .then(quiz => {

        this.setState({quiz})
      })
      .catch(error => this.props.router.replace('/quizzes'))
  }

  componentWillUnmount() {
    this.state.quiz && this.state.quiz.unlisten()
  }

  tabPath(path) {
    const countOfSlashes = (path.match(/\//g) || []).length

    if (countOfSlashes > 3) {
      return path.substr(0, path.lastIndexOf('/'))
    } else {
      return path
    }
  }

  render() {
    const {
      router,
      children,
      params: { id }
    } = this.props

    const {
      quiz
    } = this.state
    return (
      quiz && <div>
        <Tabs onChange={router.push} value={this.tabPath(this.props.location.pathname)}>
          <Tab label="Start Page" value={`/quizzes/${id}/edit`} />
          <Tab label="Questions" value={`/quizzes/${id}/questions`} />
          <Tab label="Outcomes" value={`/quizzes/${id}/offers`} />
        </Tabs>
        <div>
          {children && React.cloneElement(children, { quiz })}
        </div>
      </div>
    )
  }
}

export default withRouter(QuizEditHeader)
