// We only need to import the modules necessary for initial render
import { curry } from 'ramda'
import * as auth from 'services/auth'

import { CoreLayout } from '../layouts/CoreLayout/CoreLayout'
import QuizAnalytics from './Analytics'
import Dashboard from './Dashboard'
import Home from './Home'
import GoogleAnalytics from './quizzes/GoogleAnalytics'
import LeadGeneration from './quizzes/LeadGeneration'
import QuizEdit from './quizzes/QuizEdit'
import QuizIndex from './quizzes/QuizIndex'
import QuizPreview from './quizzes/QuizPreview'

const requireAuth = curry((prevState, nextState, replace) => {
  if (nextState.location.pathname === '/') {
    if (auth.isLoggedIn()) {
      replace({ pathname: '/quizzes' })
    }
  } else if (!auth.isLoggedIn()) {
    auth.logout()
    replace({ pathname: '/' })
  }
})

export const createRoutes = store => ({
  path: '/',
  component: CoreLayout,
  indexRoute: Home,
  childRoutes: [
    QuizIndex(store),
    QuizEdit(store),
    QuizPreview(store),
    QuizAnalytics(store),
    GoogleAnalytics(store),
    LeadGeneration(store),
    {
      path: '/dashboard',
      component: Dashboard
    }
  ],
  onChange: requireAuth,
  onEnter: requireAuth(null)
})

/*  Note: childRoutes can be chunked or otherwise loaded programmatically
    using getChildRoutes with the following signature:

    getChildRoutes (location, cb) {
      require.ensure([], (require) => {
        cb(null, [
          // Remove imports!
          require('./Counter').default(store)
        ])
      })
    }

    However, this is not necessary for code-splitting! It simply provides
    an API for async route definitions. Your code splitting should occur
    inside the route `getComponent` function, since it is only invoked
    when the route exists and matches.
*/

export default createRoutes
