import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { login, logout } from 'modules/auth'
import RaisedButton from 'material-ui/RaisedButton'
import FlatButton from 'material-ui/FlatButton'
import { ToolbarGroup, ToolbarTitle } from 'material-ui/Toolbar'
import debug from 'services/debug'

const mailto = 'mailto:info@furtherinsights.com?subject=QMS+Help+Request'

const LoggedIn = ({handleLogout, profile}) => (
  <ToolbarGroup>
    <FlatButton label="Help" href={mailto} target="_blank" primary />
    <ToolbarTitle text={profile.email} />
    <FlatButton label="Logout" onClick={handleLogout} />
  </ToolbarGroup>
)

const Login = ({handleLogin}) => (
  <ToolbarGroup>
    <FlatButton label="Help" href={mailto} target="_blank" primary />
    <RaisedButton label="Login" onClick={handleLogin} primary />
  </ToolbarGroup>
)

class Auth extends Component {
  tosLoginCallback(accepted) {
    debug.log('Auth: ToS accepted= ' + accepted)
    if (accepted) {
      this.props.login()
    } // else abort login
  }

  doLogin(initiateToSCheck) {
    // initiate the ToS acceptance UI
    initiateToSCheck(this.tosLoginCallback.bind(this))
  }

  render() {
    const { auth: { isAuthenticated, profile }, onClickTOSCheck } = this.props
    if (isAuthenticated) {
      return <LoggedIn profile={profile} handleLogout={this.props.logout} />
    } else {
      //return <Login handleLogin={this.props.login} />
      return <Login handleLogin={() => this.doLogin(onClickTOSCheck)} />
    }
  }
}

Auth.propTypes = {
  onClickTOSCheck: PropTypes.func.isRequired
}

const mapStateToProps = (state, ownProps) => ({
  auth: state.auth
})

export default connect(mapStateToProps, { login, logout })(Auth)
