import { Attr, HasOne, Model } from 'jsorm'

import ApplicationRecord from './ApplicationRecord'
import { Quiz } from './Quiz'

@Model()
export class DuplicateQuiz extends ApplicationRecord {
  static jsonapiType = 'duplicate_quiz'
  @Attr() id: string
  @HasOne() quiz: Quiz
}
